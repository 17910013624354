@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.quote {
  color: $vroom-teal;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 320px;
  @include only-on(lgUp) {
    min-height: 420px;
  }
}

.quote__content {
  @include only-on(sm) {
    max-width: 280px;
  }

  @include only-on(md) {
    max-width: 320px;
  }

  @include only-on(lg) {
    max-width: 600px;
  }

  @include only-on(xl) {
    max-width: 900px;
  }
}

.quote__quote {
  @extend .quoteText;
}

.quote__author {
  @extend .quoteAttributionText;
}

.quote__authorDescription {
  @extend .quoteAttributionTextSmall;
}
